












import Component from 'vue-class-component';

import { BaseVue } from '../BaseVue';
import ScimToken from '../components/org/ScimToken.vue';
@Component({
  components: {
    ScimToken,
  },
})
export default class SCIMClass extends BaseVue {
  public selectedTab = 0;
}
