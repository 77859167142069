<template>
  <v-layout class="elevation-3" color="white">
    <v-flex xs12 v-if="isLoading > 0">
      <v-progress-linear indeterminate color="green"></v-progress-linear>
    </v-flex>
    <v-flex xs12 class="pa-1" v-else>
      <v-container>
        <v-form ref="orgForm" v-model="valid" lazy-validation>
          <v-text-field v-model="scimBaseUrl" label="SCIM Base Url" required :disabled="true"></v-text-field>
          <v-text-field
            v-model="tempScimToken"
            label="SCIM Token"
            required
            :disabled="true"
            :type="isNewTokenGenerated ? 'text' : 'password'"
          ></v-text-field>
          <h3 class="" v-if="isNewTokenGenerated">Copy your SCIM token. It won't be displayed again.</h3>

          <div class="form-btn">
            <v-btn @click="copyToken" v-if="isNewTokenGenerated" color="success">Copy Token</v-btn>
            <v-btn @click="generateToken" color="success" v-if="checkScope(scopeLiterals.Scim)">{{
              tempScimToken ? 'Regenerate' : 'Generate'
            }}</v-btn>
            <v-btn @click="disableToken" color="success" v-if="checkScope(scopeLiterals.Scim)">Disable</v-btn>
          </div>
        </v-form>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios';
import { v4 as uuid } from 'uuid';

export default {
  data() {
    return {
      error: null,
      isLoading: 0,
      coupon: '',
      complete: false,
      updateComplete: false,
      orgInfo: {},
      subscribeLoading: false,
      updateLoading: false,
      isNewTokenGenerated: false,
      tempScimToken: '',
      scimBaseUrl: `https://api.bitwave.io/orgs/${this.$store.state.currentOrg.id}/scim/v2`,
    };
  },
  mounted() {
    if (this.$store.state.currentOrg.scimEnabled) {
      // Let's user know that scim is enabled
      this.tempScimToken = uuid();
    }
  },
  methods: {
    async generateToken() {
      const url = `${process.env.VUE_APP_API_URL}v2/orgs/${this.$store.state.currentOrg.id}/tokens/scim-token`;
      const response = await axios.post(url, { withCredentials: true });
      this.tempScimToken = response.data;
      this.isNewTokenGenerated = true;
    },
    async copyToken() {
      await navigator.clipboard.writeText(this.tempScimToken);
      this.tempScimToken = uuid();
      this.isNewTokenGenerated = false;
    },
    async disableToken() {
      const url = `${process.env.VUE_APP_API_URL}v2/orgs/${this.$store.state.currentOrg.id}/tokens/scim-token`;
      await axios.delete(url, { withCredentials: true });
      this.tempScimToken = '';
      this.isNewTokenGenerated = false;
    },
  },
};
</script>
